import { angularCoreServices, AuthInterceptor } from '@aa/angular/core';
import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
  isDevMode,
} from '@angular/core';
import { appRoutes } from './app.routes';
import {
  customerAppEffects,
  customerAppMetaReducers,
  customerAppReducers,
} from '@aa/angular/customer';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideClientHydration } from '@angular/platform-browser';
import { provideEffects } from '@ngrx/effects';
import { provideRouter } from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    ...angularCoreServices,
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideStore(customerAppReducers, {
      metaReducers: customerAppMetaReducers,
    }),
    provideEffects(...customerAppEffects),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    importProvidersFrom(
      FormlyModule.forRoot({
        validationMessages: [
          {
            name: 'required',
            message: 'This field is required.',
          },
          {
            name: 'email',
            message: 'Must be a valid email',
          },
          {
            name: 'min-password',
            message:
              'Must be at least 6 characters long and have at least 1 special character',
          },
          {
            name: 'confirm-password',
            message: 'Must match password',
          },
        ],
        validators: [
          {
            name: 'min-password',
            validation: (fieldControl) => {
              const newPassword = fieldControl.value;
              const specialCharacterRegex = new RegExp(/[^A-Za-z0-9]/g);
              return (
                newPassword &&
                newPassword.length > 5 &&
                specialCharacterRegex.test(newPassword)
              );
            },
          },
        ],
      }),
      FormlyMaterialModule,
    ),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
